<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Category</strong>
          </CCardHeader>
          <CRow>
            <CCol md="10">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Category Name *"
                    v-model="category.categoryName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Category Name'"
                    @blur="$v.category.categoryName.$touch()"
                    :is-valid="
                      $v.category.categoryName.$dirty
                        ? !$v.category.categoryName.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Department Category",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      departmentId: "",
      categoryId: "",
      category: {
        departmentId: this.$route.params.departmentId,
        categoryId: "0",
        categoryName: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    category: {
      categoryName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.departmentId = this.$route.params.departmentId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.departmentId = this.$route.params.departmentId;
      this.categoryId = this.$route.params.categoryId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.departmentId = this.$route.params.departmentId;
      this.categoryId = this.$route.params.categoryId;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Department Categories",
        params: { departmentId: this.$route.params.departmentId },
      });
    },
    // Save() {
    //   this.$v.department.$touch();
    //   if (this.$v.department.$invalid) {
    //     console.log("form is invalid", JSON.stringify(this.$v.department));
    //   }else{
    //  this.apiPostSecure(process.env.VUE_APP_API_HOST + "/web/faq/save", this.department
    //   ).then((post) => {
    //       //this.$router.push({ name: "Departments" });
    //       this.$router.push({ name: "Departments", params: {subGroupId: this.$route.params.subGroupId }});
    //     });
    //   }

    // },

    Save() {
      this.$v.category.$touch();
      if (this.$v.category.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.category));
      } else {
        //  fetch(
        //     process.env.VUE_APP_API_HOST +
        //       "/emapp/web/department/category/save/" +
        //       this.departmentId,
        //     {
        //       method: "POST",
        //       headers: {
        //         "Content-type": "application/json",
        //       },
        //       body: JSON.stringify(this.category),
        //     }
        //   )
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/department/category/save/" +
            this.departmentId,
          this.category
        ).then((category) => {
          console.log(JSON.stringify(category));
          // this.$router.push({ name: "Groups" });
          this.$router.push({
            name: "Department Categories",
            params: { departmentId: this.$route.params.departmentId },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/category/" +
          this.categoryId
      )
        .then((response) => response.json())
        .then((data) => (this.category = data));
    },
    // getRoles() {
    // fetch(process.env.VUE_APP_API_HOST +"/web/secure/meta/roles")
    //     .then((response) => response.json())
    //     .then( (data)  => (this.roles = data) );
    // },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
